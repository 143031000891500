import React from "react";
import { CtaButton } from "../../ReusableComponents";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../Common/constants";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import $ from "jquery";

const Header = () => {
  $(window).on("load", function () {
    initHeader();
  });
  function initHeader() {
    const showAnim = gsap
      .from(".header__wrap", {
        yPercent: -100,
        paused: true,
        duration: 0.2,
      })
      .progress(1);

    ScrollTrigger.create({
      start: "top top",
      end: 99999,
      onUpdate: (self) => {
        self.direction === -1 ? showAnim.play() : showAnim.reverse();
      },
    });
  }

  return (
    <>
      <header className="header__wrap">
        <div className="main__container">
          <div className="header__grid">
            <div className="header__logo">
              <a href="/">
                <img
                  src={`${IMAGE_BASE_URL}/beyond-dark.svg?v=${IMAGE_VERSION}`}
                  alt="beyond-logo"
                  loading="lazy"
                />
              </a>
            </div>
            <div className="header__cta">
              <div className="header__cta-mail">
                <a href="mailto:curious@beyonddesign.cc">
                  <span>curious@beyonddesign.cc</span>
                </a>
              </div>
              <div className="header__cta-btn">
                <CtaButton
                  btnCls="btn btn__primary"
                  iconCls=""
                  btnText={`${`Contact Us`.toUpperCase()}`}
                  btnState={false}
                />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
