import React from "react";
import MegaFooter from "./MegaFooter";
import Coypright from "./Coypright";

const Footer = () => {
  return (
    <>
      <footer className="footer__wrap">
        <MegaFooter />
        <Coypright />
      </footer>
    </>
  );
};

export default Footer;
