import React from "react";
import { IMAGE_BASE_URL, IMAGE_VERSION } from "../../../Common/constants";

const socialArr = [
  {
    iconName: "mail",
    link: "mailTo:curious@beyonddesign.cc",
    target: "",
  },
  {
    iconName: "instagram",
    link: "https://www.instagram.com/beyonddesignhq/",
    target: "_blank",
  },
  {
    iconName: "linkedin",
    link: "https://www.linkedin.com/company/beyond-design-hq/",
    target: "_blank",
  },
  {
    iconName: "twitter",
    link: "https://twitter.com/BeyondDesignHQ?s=08",
    target: "_blank",
  },
  {
    iconName: "medium",
    link: "https://medium.com/@beyond.design",
    target: "_blank",
  },
];

const MegaFooter = () => {
  return (
    <>
      <section className="sitemap__wrap">
        <div className="main__container">
          <div className="sitemap__grid">
            <div className="sitemap__grid-item">
              <div className="brand__logo">
                <a href="/">
                  <img
                    src={`${IMAGE_BASE_URL}/beyond-light.svg?v=${IMAGE_VERSION}`}
                    alt="beyond-logo"
                    loading="lazy"
                  />
                </a>
              </div>
              <div className="brand__address">
                <span>
                  546, Ijmima Complex, Malad, Mindspace, Malad West, Mumbai,
                  Maharashtra 400064.
                </span>
              </div>
            </div>
            <div className="sitemap__grid-item">
              <h3 className="sitemap__heading">
                <span>Company</span>
              </h3>
              <div className="sitemap__list">
                <ul>
                  <li>
                    <a href="/">Work</a>
                  </li>
                  <li>
                    <a href="/">About us</a>
                  </li>
                  <li>
                    <a href="/">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sitemap__grid-item">
              <h3 className="sitemap__heading">
                <span>Follow us on</span>
              </h3>
              <div className="sitemap__social">
                {socialArr.map((item, index) => (
                  <a
                    key={index}
                    href={item.link}
                    target={item.target}
                    rel="noopener noreferrer"
                  >
                    <i className={`bi-${item.iconName}`}></i>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MegaFooter;
